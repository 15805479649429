import {
    Checkbox,
    Icons,
    NumberInput,
    Select,
    SelectOptionItem,
    Slider,
    styled,
} from '@keypro/2nd-xp';
import { PrintMenuSetting } from './PrintMenuSetting';
import React from 'react';
import { t } from 'i18next';

const paperSizeOptions: SelectOptionItem[] = [
    {
        label: 'A4',
        value: '1',
    },
    {
        label: '3xA4',
        value: '2',
    },
    {
        label: '4xA4',
        value: '3',
    },
    {
        label: '5xA4',
        value: '4',
    },
    {
        label: 'A3',
        value: '5',
    },
    {
        label: 'A2',
        value: '6',
    },
    {
        label: 'A1',
        value: '7',
    },
    {
        label: 'A0',
        value: '8',
    },
];

const dpiOptions: SelectOptionItem[] = [
    {
        label: '150',
        value: '1',
    },
    {
        label: '300',
        value: '2',
    },
    {
        label: '450',
        value: '3',
    },
    {
        label: '600',
        value: '4',
    },
];

export const PrintMenuSettingPart = () => {
    const [rotation, setRotation] = React.useState(0);
    const [orientation, setOrientation] = React.useState('landscape');

    return (
        <PrintMenuSetting
            icon={<Icons.PrintSettings />}
            title={t('settings')}
            isOn={false}
        >
            <StyledPrintMenuSettingTextField>
                <StyledPrintMenuSettingTextFieldLabel>
                    {t('paperSize')}
                </StyledPrintMenuSettingTextFieldLabel>
                <StyledSelect
                    isFilterable
                    options={paperSizeOptions}
                    defaultValue="1"
                />
            </StyledPrintMenuSettingTextField>
            <StyledPrintMenuSettingTextField>
                <StyledPrintMenuSettingTextFieldLabel>
                    DPI
                </StyledPrintMenuSettingTextFieldLabel>
                <StyledSelect
                    isFilterable
                    options={dpiOptions}
                    defaultValue="2"
                />
            </StyledPrintMenuSettingTextField>
            <StyledPrintMenuSettingTextField>
                <StyledPrintMenuSettingTextFieldLabel>
                    {t('rotation')}
                </StyledPrintMenuSettingTextFieldLabel>
                <StyledPrintMenuSettingTextFieldNumberInput>
                    <NumberInput
                        value={rotation}
                        min={-360}
                        max={360}
                        symbol="º"
                        onChange={(value) => {
                            setRotation(value);
                        }}
                        style={{ width: '52px' }}
                    />
                    <Slider
                        min="-360"
                        max="360"
                        value={rotation.toString()}
                        onChange={(value) => {
                            setRotation(Number(value));
                        }}
                        style={{ width: '113px' }}
                    />
                </StyledPrintMenuSettingTextFieldNumberInput>
            </StyledPrintMenuSettingTextField>
            <StyledPrintMenuSettingRadio>
                <StyledPrintMenuSettingRadioTitle>
                    {t('orientation')}
                </StyledPrintMenuSettingRadioTitle>
                <StyledPrintMenuSettingRadioGroup>
                    <StyledPrintMenuSettingRadioItem>
                        <StyledCheckbox
                            type="radio"
                            id="landscape"
                            name="orientation"
                            onChange={() => {
                                setOrientation('landscape');
                            }}
                            checked={orientation === 'landscape'}
                        />
                        <StyledPrintMenuSettingRadioItemLabel>
                            <Icons.Landscape />
                            {t('landscape')}
                        </StyledPrintMenuSettingRadioItemLabel>
                    </StyledPrintMenuSettingRadioItem>
                    <StyledPrintMenuSettingRadioItem>
                        <StyledCheckbox
                            type="radio"
                            id="portrait"
                            name="orientation"
                            onChange={() => {
                                setOrientation('portrait');
                            }}
                            checked={orientation === 'portrait'}
                        />
                        <StyledPrintMenuSettingRadioItemLabel>
                            <Icons.Portrait />
                            {t('portrait')}
                        </StyledPrintMenuSettingRadioItemLabel>
                    </StyledPrintMenuSettingRadioItem>
                </StyledPrintMenuSettingRadioGroup>
            </StyledPrintMenuSettingRadio>
        </PrintMenuSetting>
    );
};

const StyledPrintMenuSettingTextField = styled.div`
    display: flex;
    align-items: center;
`;

const StyledSelect = styled(Select)`
    width: 185px;
    height: 32px;
    margin-left: auto;
`;

const StyledPrintMenuSettingTextFieldLabel = styled.div`
    ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;

const StyledPrintMenuSettingTextFieldNumberInput = styled.div`
    display: flex;
    gap: 8px;
    width: 185px;
    height: 32px;
    margin-left: auto;
`;

const StyledPrintMenuSettingRadio = styled.div`
    padding-top: 8px;
`;

const StyledPrintMenuSettingRadioTitle = styled.div`
    ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;

const StyledPrintMenuSettingRadioGroup = styled.div`
    display: flex;
    gap: 16px;
`;

const StyledPrintMenuSettingRadioItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const StyledCheckbox = styled(Checkbox)`
    margin-left: 8px;
    & > svg {
        width: 18px;
        height: 18px;
    }
`;

const StyledPrintMenuSettingRadioItemLabel = styled.label`
    display: flex;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    gap: 4px;
    & > svg {
        width: 20px;
        height: 20px;
    }
`;
