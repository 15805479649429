import { create } from 'zustand';
import { InfoToolSelectionMode } from '@keypro/2nd-xp';
import { ReactNode } from 'react';

interface MenuState {
    menuContentId: string | null;
    menuContent: ReactNode;
    isMenuOpen: boolean;
    infoToolSelectionMode: InfoToolSelectionMode | null;
    setMenuContent: (contentID: string, content: ReactNode) => void;
    toggleMenu: () => void;
    setInfoToolSelectionMode: (mode: InfoToolSelectionMode) => void;
}

const createMenuStore = () =>
    create<MenuState>((set) => ({
        menuContentId: null,
        menuContent: null,
        isMenuOpen: false,
        infoToolSelectionMode: InfoToolSelectionMode.RECTANGLE,
        setMenuContent: (contentID, content) =>
            set((state) => ({
                menuContentId: contentID,
                menuContent: content,
                isMenuOpen:
                    contentID === state.menuContentId
                        ? !state.isMenuOpen
                        : true,
            })),
        toggleMenu: () => set((state) => ({ isMenuOpen: !state.isMenuOpen })),
        setInfoToolSelectionMode: (mode) =>
            set({ infoToolSelectionMode: mode }),
    }));

export const useLeftMenu = createMenuStore();
export const useRightMenu = createMenuStore();
export const useCenterMenu = createMenuStore();
