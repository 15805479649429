import { Icons, Input, styled } from '@keypro/2nd-xp';
import { PrintMenuSetting } from './PrintMenuSetting';
import { t } from 'i18next';
import React from 'react';

export const PrintMenuLegendPart = () => {
    const [isDisableSetting, setIsDisableSetting] = React.useState(false);
    return (
        <PrintMenuSetting
            icon={<Icons.PrintLegend />}
            title={t('legend')}
            isOn={true}
            isDisableSetting={isDisableSetting}
            onChangeDisabled={() => setIsDisableSetting(!isDisableSetting)}
        >
            <StyledPrintMenuSettingTextField>
                <Input
                    inputProps={{
                        placeholder: t('createdBy'),
                    }}
                    disabled={!isDisableSetting}
                />
            </StyledPrintMenuSettingTextField>
            <StyledPrintMenuSettingTextField>
                <Input
                    multiline={true}
                    inputProps={{
                        placeholder: t('addNote'),
                    }}
                    disabled={!isDisableSetting}
                />
            </StyledPrintMenuSettingTextField>
        </PrintMenuSetting>
    );
};

const StyledPrintMenuSettingTextField = styled.div`
    display: flex;
    & textarea {
        display: flex;
        box-sizing: border-box;
        height: 88px;
    }
`;
