import { NavigationButton, Icons } from '@keypro/2nd-xp';
import { useLeftMenu } from '@stores';
import { LayersMenu } from './LayersMenu';
import { t } from 'i18next';

export const LayersButton = (): JSX.Element => {
    const { setMenuContent, menuContentId, isMenuOpen } = useLeftMenu();
    const label = t('Layers');

    return (
        <NavigationButton
            data-testid="navigationbar-layers"
            label={label}
            className={menuContentId === label && isMenuOpen ? 'active' : ''}
            onClick={() => setMenuContent(label, <LayersMenu />)}
        >
            <Icons.Layers />
        </NavigationButton>
    );
};
