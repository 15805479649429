import { Button, Icons, StateHint, styled, Toolbar } from '@keypro/2nd-xp';
import { t } from 'i18next';
import { useState } from 'react';
import { CoordinatesTool } from './CoordinatesTool';

const Coordinates = styled.div`
    height: 32px;
    display: flex;
    gap: 24px;
    margin-right: 8px;
    position: relative;
`;

const CoordinateItem = styled.span`
    margin: auto;
`;

const CoordinateBtn = styled(Button)`
    width: 36px;
    height: 32px;
    & > svg {
        width: 20px;
        height: 20px;
    }
`;

const AppFooter = (): JSX.Element => {
    const [isCoordinatesToolOpen, setIsCoordinatesToolOpen] = useState(false);
    const [coordinateSystemName, setCoordinateSystemName] =
        useState('Google EPSG 900913');
    return (
        <Toolbar>
            <StateHint style={{ paddingLeft: 45 }} />
            <Coordinates style={{ justifyContent: 'end' }}>
                <CoordinateItem data-tooltip-top={t('srsTooltip')} id="srs">
                    {coordinateSystemName}
                </CoordinateItem>
                <CoordinateItem
                    data-tooltip-top={t('coordinateTooltip')}
                    id="coordinates"
                    data-testid="coordinates"
                >
                    55.750655, 37.653668, 18
                </CoordinateItem>
                <CoordinateItem>
                    <CoordinateBtn
                        kind="primary"
                        id="coordinate-button"
                        onClick={() => {
                            setIsCoordinatesToolOpen(!isCoordinatesToolOpen);
                        }}
                    >
                        <Icons.Settings2 />
                    </CoordinateBtn>
                </CoordinateItem>
                <CoordinatesTool
                    isOpenTool={isCoordinatesToolOpen}
                    onActionClickTool={setIsCoordinatesToolOpen}
                    onChangeCoordinateSystem={setCoordinateSystemName}
                />
            </Coordinates>
        </Toolbar>
    );
};

export default AppFooter;
