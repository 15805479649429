import { NavigationButton, Icons } from '@keypro/2nd-xp';
import { useLeftMenu } from '@stores';
import { MapViewMenu } from './MapViewMenu';
import { t } from 'i18next';

/**
 * MapviewButton that opens the MapviewMenu in the left menu.
 */
export const MapViewButton = (): JSX.Element => {
    const { setMenuContent, menuContentId, isMenuOpen } = useLeftMenu();
    const label = t('views');

    return (
        <NavigationButton
            data-testid="navigationbar-views"
            label={label}
            className={menuContentId === label && isMenuOpen ? 'active' : ''}
            onClick={() => setMenuContent(label, <MapViewMenu />)}
        >
            <Icons.MapView />
        </NavigationButton>
    );
};
