import { FormGroup } from '@form-configs';

// // Example groups
export const groupsSearch: FormGroup[] = [
    {
        name: 'manholesearch',
        fields: [
            {
                name: 'type',
                filter: {
                    groupname: { eq: 'MANHOLE_TYPE' },
                },
                component: 'combobox-multi',
            },
            {
                name: 'plan',
                component: 'combobox-multi',
            },
            {
                name: 'owner',
                filter: {
                    groupname: { eq: 'MANHOLE_OWNER' },
                },
                component: 'combobox-multi',
            },
            {
                name: 'usageState',
                filter: {
                    groupname: { eq: 'MANHOLE_STATE' },
                },
            },
            {
                name: 'renter',
                filter: {
                    groupname: { eq: 'MANHOLE_OWNER' },
                },
                component: 'combobox-multi',
            },
            'identification',
            'createdBy',
            'updatedBy',
            'remarks',
            'surveyAmount',
            'mapper',
            'location',
            {
                name: 'locationAccuracy',
                filter: {
                    groupname: { eq: 'LOCATION_ACCURACY' },
                },
            },
            {
                name: 'telecomArea',
                component: 'combobox',
            },
            {
                name: 'mappingMethod',
                filter: {
                    groupname: { eq: 'MAPPING_METHOD' },
                },
                component: 'combobox-multi',
            },
            {
                name: 'heightAccuracy',
                filter: {
                    groupname: { eq: 'H_ACCURACY' },
                },
                component: 'combobox-multi',
            },
            {
                name: 'safetyDistance',
                filter: {
                    groupname: { eq: 'SAFETY_DISTANCE' },
                },
                component: 'combobox-multi',
            },
            {
                name: 'diagramConfirm',
                filter: {
                    groupname: { eq: 'DIAGRAM_PAPER_SIZE' },
                },
            },
        ],
    },
];
