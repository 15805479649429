import {
    SideMenu,
    styled,
    Button,
    Icons,
    MapContext,
    MapParams,
} from '@keypro/2nd-xp';
import React, { useContext, useState, useEffect } from 'react';
import { t } from 'i18next';
import { MapViewList } from './MapViewList';

const MapviewMenuHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    max-height: 32px;
    justify-content: space-between;

    .close-search-icon {
        width: 16px;
        height: 16px;
        path {
            stroke: ${({ theme }) => theme.colors.neutral['90']};
        }
    }
    .input-search-icon {
        width: 16px;
        height: 16px;
        & path {
            fill: ${({ theme }) => theme.colors.neutral['80']};
            stroke: ${({ theme }) => theme.colors.neutral['80']};
        }
    }

    .title {
        ${({ theme }) => theme.fonts['18px Bold']};
        font-weight: 700;
    }
`;

const StyledInputField = styled.input`
    font: ${({ theme }) => theme.fonts['14px Regular']};
    line-height: 0;
    color: ${({ theme }) => theme.colors.neutral['100']};
    background-color: transparent;
    width: 100%;
    height: 18px;
    border: none;
    caret-color: ${({ theme }) => theme.colors.accents.blue['10']};
    &::placeholder {
        color: ${({ theme }) => theme.colors.neutral['80']};
    }
    &:focus {
        outline-color: transparent;
        outline-style: none;
    }
`;

const InputFieldIconContainer = styled.div`
    width: 16px;
    height: 16px;
`;

const SearchButton = styled(Button).attrs({ kind: 'secondary' })`
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.colors.neutral['30']};
    & svg {
        width: 16px;
        height: 16px;
        & path {
            fill: ${({ theme }) => theme.colors.neutral['90']};
            stroke: ${({ theme }) => theme.colors.neutral['90']};
        }
    }
`;

const NewViewButton = styled(Button).attrs({ kind: 'secondary' })`
    width: 85%;
    --icon-color: ${({ theme, disabled }) =>
        disabled
            ? theme.colors.disabled['80-transparent-60']
            : theme.colors.accents.blue['10']};
    & svg {
        width: 20px;
        height: 20px;
        path {
            stroke: var(--icon-color);
        }
    }
`;

export interface MapView {
    /** ID of the MapView */
    id: string;
    /** Displayed name */
    name: string;
    /** Creation date/time */
    date: string;
    /** Map parameters for the view */
    data: MapParams;
}

interface MapViewMenuHeaderSearchProps {
    /** Function to handle input change */
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    /** Function to toggle search state */
    toggleSearchState: () => void;
}

interface MapViewMenuHeaderTitleProps {
    /** Function to toggle search state */
    toggleSearchState: () => void;
}

/**
 * Get the saved map views from local storage
 * @returns
 */
const getSavedMapViews = (): MapView[] => {
    const savedViews = localStorage.getItem('map-views');
    return savedViews ? JSON.parse(savedViews) : [];
};

/**
 * Save the map views to local storage
 * @param
 */
const saveMapViews = (newViews: MapView[]) => {
    localStorage.setItem('map-views', JSON.stringify(newViews));
};

/**
 * Map view menu component that displays a list of saved map views
 * and allows the user to add new views or search for existing ones.
 * @returns
 */
export const MapViewMenu = (): JSX.Element => {
    const controller = useContext(MapContext)!;
    const [searchState, setSearchState] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [editingView, setEditingView] = useState<string | null>(null);
    const [mapViews, setMapViews] = useState<MapView[]>(getSavedMapViews());
    const [isMapInitialized, setIsMapInitialized] = useState(
        controller.initialized,
    );

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const toggleSearchState = () => {
        setSearchState((prevState) => !prevState);
        setSearchValue('');
    };

    const addView = () => {
        const maxId = mapViews.reduce(
            (max, view) => Math.max(max, parseInt(view.id, 10)),
            0,
        );
        const newView: MapView = {
            id: `${maxId + 1}`,
            name: t('newMapView'),
            date: new Date().toISOString(),
            data: controller.getMapParams(),
        };
        setMapViews([newView, ...getSavedMapViews()]);
        setEditingView(newView.id);
    };

    useEffect(() => {
        saveMapViews(mapViews);
    }, [mapViews]);

    useEffect(() => {
        const handleInitialize = () => {
            setIsMapInitialized(true);
        };
        controller.on('initialize', handleInitialize);

        return () => {
            controller.off('initialize', handleInitialize);
        };
    }, [controller]);

    return (
        <SideMenu>
            <MapviewMenuHeader data-testid="mapview-menu-header">
                {searchState ? (
                    <MapViewMenuHeaderSearchField
                        handleInputChange={handleInputChange}
                        toggleSearchState={toggleSearchState}
                    />
                ) : (
                    <MapViewMenuHeaderTitle
                        toggleSearchState={toggleSearchState}
                    />
                )}
            </MapviewMenuHeader>
            {isMapInitialized ? (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <NewViewButton
                            kind="secondary"
                            iconPosition="left"
                            label={t('addNewMapView')}
                            style={{ width: '85%' }}
                            onClick={addView}
                            disabled={editingView !== null}
                            data-testid="mapview-menu-new-button"
                        >
                            {<Icons.Plus />}
                        </NewViewButton>
                    </div>
                    <MapViewList
                        search={searchValue}
                        mapViews={mapViews}
                        setMapViews={setMapViews}
                        saveMapViews={saveMapViews}
                        editingView={editingView}
                        setEditingView={setEditingView}
                    />
                </>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Icons.Spinner />
                </div>
            )}
        </SideMenu>
    );
};

const MapViewMenuHeaderSearchField = ({
    handleInputChange,
    toggleSearchState,
}: MapViewMenuHeaderSearchProps) => {
    return (
        <>
            <InputFieldIconContainer>
                <Icons.Search className="input-search-icon" />
            </InputFieldIconContainer>
            <StyledInputField
                type="text"
                placeholder={t('searchMapViews')}
                onChange={handleInputChange}
                autoFocus
            />
            <Button
                data-tooltip={t('closeSearch')}
                kind="ghost"
                onClick={toggleSearchState}
                style={{ width: '24px', height: '24px' }}
                data-testid="mapview-menu-close-search-button"
            >
                <Icons.Cross className="close-search-icon" />
            </Button>
        </>
    );
};

const MapViewMenuHeaderTitle = ({
    toggleSearchState,
}: MapViewMenuHeaderTitleProps) => {
    return (
        <>
            <span className="title">{t('mapViews')}</span>
            <SearchButton
                onClick={toggleSearchState}
                data-tooltip={t('searchMapViews')}
                data-testid="mapview-menu-search-button"
            >
                <Icons.Search />
            </SearchButton>
        </>
    );
};
