import {
    Toolbar,
    ToolbarGroup,
    Button,
    Icons,
    PreviousMapHistoryButton,
    NextMapHistoryButton,
    LocateMeButton,
    PlacementScaleMenu,
    ZoomSlider,
    ZoomToAreaButton,
    DisplayScaleMenu,
    KeyCombo,
    Key,
    InfoToolMenu,
    PanButton,
    MeasureToolMenu,
    MoreMenu,
    HelpButton,
} from '@keypro/2nd-xp';
import { UserWidget } from './UserWidget/UserWidget';
import Bell from '@assets/mockups/Bell.svg';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { PrintMenu, PrintPreview } from '@components/PrintMapOverlay';
import { useCenterMenu, useRightMenu } from '@stores';
import SearchField from '@components/Search/SearchField';

const helpUrl = 'https://support.keypro.fi';

const zoomToAreaHint = (
    <Trans i18nKey="zoomToAreaHint">
        <KeyCombo>
            <Key>Click</Key>
            <Icons.Plus />
            <Key>Drag</Key>
        </KeyCombo>
        to select an area to zoom into
    </Trans>
);

const infoToolRectangleHint = (
    <Trans i18nKey="infoToolRectangleHint">
        <KeyCombo>
            <Key>Click</Key>
            <Icons.Plus />
            <Key>Drag</Key>
        </KeyCombo>
        to select an area
    </Trans>
);

const infoToolPolygonHint = (
    <Trans i18nKey="infoToolPolygonHint">
        <Key>Click</Key> to select an area
        <Key>Double-Click</Key> to finish
    </Trans>
);

const infoToolFreehandHint = (
    <Trans i18nKey="infoToolFreehandHint">
        <KeyCombo>
            <Key>Click</Key>
            <Icons.Plus />
            <Key>Drag</Key>
        </KeyCombo>
        to select an area
    </Trans>
);

const infoToolAreaHint = (
    <Trans i18nKey="infoToolAreaHint">
        <Key>Click</Key> to select an area
    </Trans>
);

const measureToolDistanceHint = (
    <Trans i18nKey="measureToolDistanceHint">
        <Key>Click</Key> to draw a line <Key>Double-Click</Key> to finish
    </Trans>
);

const measureToolAreaHint = (
    <Trans i18nKey="measureToolAreaHint">
        <Key>Click</Key> to draw an area <Key>Double-Click</Key> to finish
    </Trans>
);

const googleStreetsHint = (
    <Trans i18nKey="googleStreetsHint">
        <Key>Click</Key> to select a location for the street view
    </Trans>
);

const AppToolbar = (): JSX.Element => {
    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const { setMenuContent: setCenterMenuContent, setInfoToolSelectionMode } =
        useCenterMenu();
    return (
        <Toolbar data-testid="app-toolbar" id="app-toolbar">
            <ToolbarGroup style={{ gap: 20 }}>
                <ToolbarGroup>
                    <Button kind="ghost" data-tooltip={t('menu')}>
                        <Icons.Burger />
                    </Button>
                </ToolbarGroup>
                <ToolbarGroup>
                    <SearchField data-testid="toolbar-searchField" />
                </ToolbarGroup>
                <ToolbarGroup style={{ gap: 0 }}>
                    <PreviousMapHistoryButton
                        data-tooltip={t('prevMapHistoryTooltip')}
                    />
                    <NextMapHistoryButton
                        data-tooltip={t('nextMapHistoryTooltip')}
                    />
                </ToolbarGroup>
            </ToolbarGroup>
            <ToolbarGroup>
                <LocateMeButton
                    data-testid="toolbar-locateMe"
                    data-tooltip={t('locateMeTooltip')}
                    label={t('locateMe')}
                />
                <PlacementScaleMenu
                    data-tooltip={t('placementScaleTooltip')}
                    title={t('placementScale')}
                />
                <ZoomSlider data-tooltip={t('zoomSliderTooltip')} />
                <DisplayScaleMenu
                    data-testid="toolbar-displayScale"
                    data-tooltip={t('displayScaleTooltip')}
                    title={t('displayScale')}
                />
                <ZoomToAreaButton
                    data-testid="toolbar-zoomToArea"
                    data-tooltip={t('zoomToAreaTooltip')}
                    label={t('zoomToArea')}
                    $hint={zoomToAreaHint}
                />
            </ToolbarGroup>
            <ToolbarGroup>
                <PanButton data-tooltip={t('panTooltip')} />
                <InfoToolMenu
                    data-testid="toolbar-infoToolMenu"
                    arrowTooltip={t('infoToolArrowTooltip')}
                    title={t('infoToolMenuTitle')}
                    rectangleSelectionLabel={t('infoToolRectangleLabel')}
                    rectangleSelectionHint={infoToolRectangleHint}
                    mapViewSelectionLabel={t('infoToolMapViewLabel')}
                    polygonSelectionLabel={t('infoToolPolygonLabel')}
                    polygonSelectionHint={infoToolPolygonHint}
                    freehandSelectionLabel={t('infoToolFreehandLabel')}
                    freehandSelectionHint={infoToolFreehandHint}
                    areaSelectionLabel={t('infoToolAreaLabel')}
                    areaSelectionHint={infoToolAreaHint}
                    onChangeMode={(mode) => {
                        setInfoToolSelectionMode(mode);
                    }}
                />
                <MeasureToolMenu
                    arrowTooltip={t('measureToolArrowTooltip')}
                    distanceLabel={t('measureToolDistanceLabel')}
                    distanceHint={measureToolDistanceHint}
                    areaLabel={t('measureToolAreaLabel')}
                    areaHint={measureToolAreaHint}
                    tooltipInitialText={t('measureToolTooltipInitialText')}
                    tooltipAreaLabel={t('measureToolTooltipAreaLabel')}
                    tooltipPerimeterLabel={t(
                        'measureToolTooltipPerimeterLabel',
                    )}
                />
                <Button
                    data-testid="toolbar-print"
                    kind="ghost"
                    label={t('print')}
                    data-tooltip={t('printTooltip')}
                    iconPosition="left"
                    onClick={() => {
                        setRightMenuContent(t('print'), <PrintMenu />);
                        setCenterMenuContent(t('print'), <PrintPreview />);
                    }}
                >
                    <Icons.Print />
                </Button>
                <MoreMenu
                    data-tooltip={t('moreMapOptionsTooltip')}
                    googleMapsTitle={t('googleMapsTitle')}
                    googleStreetsTitle={t('googleStreetsTitle')}
                    googleStreetsHint={googleStreetsHint}
                    copyMapLinkTitle={t('copyMapLinkTitle')}
                    copyMapLinkMessage={t('copyMapLinkMessage')}
                    refreshMapTitle={t('refreshMapTitle')}
                />
                <Icons.Divider style={{ height: '32px', width: '2px' }} />
                <Button kind="ghost" data-tooltip={t('notificationsTooltip')}>
                    <img alt="Notifications" src={Bell} />
                </Button>
                <HelpButton url={helpUrl} data-tooltip={t('helpTooltip')} />
                <UserWidget />
            </ToolbarGroup>
        </Toolbar>
    );
};

export default AppToolbar;
