import { styled } from '@keypro/2nd-xp';

export interface PrintLegendProps {
    color: string;
    title: string;
}

export const PrintLegend = ({ color, title }: PrintLegendProps) => {
    return (
        <StyledPrintLegend>
            <StyledPrintLegendEclipse $color={color} />
            <StyledPrintLegendTitle>{title}</StyledPrintLegendTitle>
        </StyledPrintLegend>
    );
};

const StyledPrintLegend = styled.span`
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.neutral['90']};
    gap: 4px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 2px;
`;

const StyledPrintLegendEclipse = styled.div<{ $color: string }>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ $color }) => $color};
    border: 1px solid rgba(255, 255, 255, 0.8);
`;

const StyledPrintLegendTitle = styled.div`
    ${(props) => props.theme.fonts['12px Bold']}
    color: ${(props) => props.theme.colors.neutral['10']};
    padding-top: 2px;
    padding-bottom: 2px;
`;
