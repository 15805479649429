import { FormBuilder, getData } from '@components/FormBuilder';
import {
    FormData as FormConfigData,
    getTypeByModel,
    isModelSupported,
    getFormConfig,
    FormGroup,
    getTranslationKey,
} from '@form-configs';
import {
    Button,
    Icons,
    Select,
    SelectOptionItem,
    styled,
} from '@keypro/2nd-xp';
import { useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import { groupsSearch } from 'src/form-configs/search-form/GroupSearch';
import { getManholes } from '@apis/keycom';
import { GraphQLFilter } from '@apis/utils';
import { SearchResult } from '@generated';
import { objectTypes } from '@components/utils';
import { useFormBuilder } from '@stores';

// Groups that should not be searchable now, will support in the future like number, date, etc.
const groupsDoNotSearch: string[] = [
    'id',
    'historyDate',
    'createdTs',
    'created_date',
    'updatedTs',
    'updated_date',
    'address',
    'installYear',
    'rentPaid',
    'angle',
    'scale',
];

export type FilterObject = {
    [key: string]: unknown; // Adjust the type as necessary
};

interface AdvancedSearchProps {
    isOpen?: boolean;
    selectedAdvancedFilters?: FilterObject;
    setSelectedAdvancedFilters?: (filters: FilterObject) => void;
    setModelAdvancedSearch?: (model: string) => void;
    setIsUsingAdvancedSearch?: (isUsingAdvancedSearch: boolean) => void;
    setDataAdvancedSearch?: (data: SearchResult[]) => void;
    onLoading: (loading: boolean) => void;
    handleClose?: () => void;
}

/**
 * Advanced search component that allows users to search for objects based on selected groups
 * @param isOpen - The state of the tool
 * @param handleClose - The function to handle the tool close
 * @returns JSX.Element
 */
export const AdvancedSearch = ({
    isOpen = false,
    selectedAdvancedFilters,
    setSelectedAdvancedFilters,
    setModelAdvancedSearch,
    setIsUsingAdvancedSearch,
    setDataAdvancedSearch,
    onLoading,
    handleClose,
}: AdvancedSearchProps) => {
    const { types } = useFormBuilder();
    const [model, setModel] = useState<string>('');
    const [data, setData] = useState<FormConfigData | undefined>(undefined);
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
    const [type, setType] = useState<string | null>(null);
    const searchString = 'Search';

    const advancedSearchRef = useRef<HTMLDivElement>(null);

    // Mapping of types to fetching hooks
    const fetchFunctions = {
        manholesearch: getManholes,
        // splicesearch: useGetSplices,
        // cablesearch: useGetCables,
        // Add more mappings as needed
    };

    // Options for the select input, memoized to optimize rendering
    const options: SelectOptionItem[] = useMemo(() => {
        if (isModelSupported(model)) {
            const gqlType = getTypeByModel(model);
            const tempType = gqlType.slice(0, -searchString.length);
            setType(gqlType);

            if (types[tempType]) {
                return types[tempType].fields
                    .map((field) => {
                        if (!groupsDoNotSearch.includes(field.name)) {
                            const translationKey = getTranslationKey(
                                gqlType,
                                field.name,
                            );

                            const label = t(translationKey);

                            if (label) {
                                return {
                                    value: field.name,
                                    label: t(translationKey),
                                };
                            }
                        }
                    })
                    .filter(
                        (option): option is SelectOptionItem =>
                            option !== undefined,
                    );
            }
        }
        return [];
    }, [types, model]);

    // Effect to handle model change and set selected groups
    useEffect(() => {
        if (isModelSupported(model)) {
            const gqlType = getTypeByModel(model);
            setType(gqlType);
            const formConfig = getFormConfig(gqlType);
            // Initialize selected groups with all available groups
            setSelectedGroups(formConfig.groups.map((group) => group.name));
        }
    }, [model]); // Depend only on model

    useEffect(() => {
        if (selectedAdvancedFilters) {
            setSelectedGroups(Object.keys(selectedAdvancedFilters));
        }
    }, [selectedAdvancedFilters]);

    // Effect to fetch data based on the selected type
    useEffect(() => {
        if (type) {
            setData(undefined);
            getData(type, {}).then((results) => {
                if (results?.length > 0) {
                    setData(results[0]);
                }
            });
        }
    }, [type]); // Depend only on type

    // Filtered configuration for form fields based on selected groups
    const filteredConfig = useMemo(() => {
        const groups: FormGroup[] = [];
        groupsSearch.forEach((group: FormGroup) => {
            if (group.name === model) {
                const newGroup: FormGroup = {
                    name: group.name,
                    fields: [],
                };
                group.fields.forEach((field) => {
                    if (
                        (typeof field !== 'string' &&
                            field.name &&
                            selectedGroups.includes(field.name)) ||
                        (typeof field === 'string' &&
                            selectedGroups.includes(field))
                    ) {
                        newGroup.fields.push(field);
                    }
                });
                groups.push(newGroup);
            }
        });
        return groups;
    }, [model, selectedGroups]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        onLoading(true);
        handleClose?.();
        const filter: FilterObject = {
            id: {
                in: [],
            },
        };

        selectedGroups.forEach((group) => {
            if (data?.[group] !== undefined) {
                const value = data[group];

                if (Array.isArray(value)) {
                    // If the value is an array, use the 'in' operator
                    filter[group] = {
                        id: {
                            in: value, // Assuming you want to filter by the array of IDs
                        },
                    };
                } else {
                    // If it's a single value, use the 'eq' operator
                    filter[group] = {
                        eq: value,
                    };
                }
            }
        });

        setSelectedAdvancedFilters?.(filter);
        setModelAdvancedSearch?.(model);
        setIsUsingAdvancedSearch?.(true);

        try {
            // Handle the fetched data
            const fetchData = await fetchFunctions[
                model as keyof typeof fetchFunctions
            ](filter as GraphQLFilter);
            const convertedData: SearchResult[] = fetchData.map((item) => {
                return {
                    id: parseInt(item.id!, 10),
                    identification: item.identification,
                    location: item.location,
                    model_name: model.slice(0, -6),
                    angle: item.angle,
                };
            });
            setDataAdvancedSearch?.(convertedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            onLoading(false);
        }
    };
    const handleClearSelection = () => {
        setSelectedGroups([]); // Clear all selections
    };

    const handleCloseAdvancedSearch = () => {
        handleClose?.();
    };

    return (
        <StyledAdvancedSearch
            $isOpen={isOpen}
            data-testid="advanced-search"
            ref={advancedSearchRef}
        >
            <StyledHeader>
                <StyledHeaderTop>
                    <StyledHeaderLeft data-testid="advanced-search-model-select">
                        <StyledHeaderTitle>{t('filters')}</StyledHeaderTitle>
                        <StyledHeaderFilter
                            placeholder="Select"
                            options={objectTypes}
                            onChangeValue={(value) => {
                                if (typeof value === 'string') {
                                    setModel(value);
                                }
                            }}
                        />
                    </StyledHeaderLeft>
                    <StyledHeaderRight
                        kind="ghost"
                        data-testid="advanced-search-close"
                        onClick={handleCloseAdvancedSearch}
                    >
                        <Icons.Cross2 />
                    </StyledHeaderRight>
                </StyledHeaderTop>
                <StyledDivider />
                <StyledHeaderBot data-testid="advanced-search-group-select">
                    <StyledFilterTitle>{t('addFilter')}</StyledFilterTitle>
                    <Select
                        placeholder={t('advancedSearch.placeholder')}
                        isMultiSelect
                        isFilterable
                        options={options}
                        value={selectedGroups}
                        filterLabels={{
                            addedFiltersText: t('advancedSearch.addedFilters'),
                            allFiltersText: t('advancedSearch.allFilters'),
                            clearSelectionText: t(
                                'advancedSearch.clearSelection',
                            ),
                        }}
                        onChangeValue={(value) => {
                            setSelectedGroups(
                                Array.isArray(value) ? value : [value],
                            );
                        }}
                        onClearSelection={handleClearSelection}
                    />
                </StyledHeaderBot>
                <StyledDivider />
            </StyledHeader>
            <form id="search-form" action="" onSubmit={handleSubmit}>
                <StyledBody $selectedGroups={selectedGroups.length}>
                    {type && data && filteredConfig && (
                        <FormBuilder
                            gqlType={type}
                            data={data}
                            customGroup={filteredConfig}
                            onChangeValue={(name, value) => {
                                setData((prev) => {
                                    if (prev) {
                                        return {
                                            ...prev,
                                            [name]: value,
                                        };
                                    }
                                    return prev;
                                });
                            }}
                        />
                    )}
                </StyledBody>
            </form>
            <StyledFooter>
                <StyledDivider />
                <StyledButtons>
                    <StyledBtnClearAll
                        label={t('clearAll')}
                        kind="secondary"
                        disabled={selectedGroups.length === 0}
                        onClick={handleClearSelection}
                        data-testid="advanced-search-clear-all"
                    />
                    <StyledBtnRight>
                        <StyledBtnCancel
                            label={t('cancel')}
                            kind="secondary"
                            onClick={handleCloseAdvancedSearch}
                            data-testid="advanced-search-cancel"
                        />
                        <StyledBtnApply
                            label={t('apply')}
                            type="submit"
                            form="search-form"
                            disabled={model === ''}
                            data-testid="advanced-search-apply"
                        />
                    </StyledBtnRight>
                </StyledButtons>
            </StyledFooter>
        </StyledAdvancedSearch>
    );
};

const StyledAdvancedSearch = styled.div<{ $isOpen?: boolean }>`
    display: ${(props) => (props.$isOpen ? 'block' : 'none')};
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 348px;
    max-height: calc(100vh - 100px);
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    background-color: ${(props) => props.theme.colors.neutral['10']};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
`;

const StyledHeader = styled.div``;

const StyledHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
`;

const StyledHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 8px;
    gap: 12px;
`;

const StyledHeaderTitle = styled.div`
    ${(props) => props.theme.fonts['16px Bold']}
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledHeaderFilter = styled(Select)`
    font-size: 13px;
    line-height: 16px;
    border-radius: 4px;
    color: ${(props) => props.theme.colors.neutral['90']};
    background-color: ${(props) => props.theme.colors.neutral['40']};
    width: 100px;
    & > button {
        & > div {
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
        }
    }
    & > ul {
        width: 168px;
    }
    & span {
        margin-top: 12px;
        & > svg:first-child {
            display: none;
        }
    }
`;

const StyledHeaderRight = styled(Button)`
    width: 32px;
    height: 32px;
    corlor: ${(props) => props.theme.colors.neutral['90']};
    & > svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledHeaderBot = styled.div`
    padding: 14px 16px;
`;

const StyledFilterTitle = styled.div`
    ${(props) => props.theme.fonts['14px Bold']}
    color: ${(props) => props.theme.colors.neutral['100']};
    padding-bottom: 8px;
`;

const StyledBody = styled.div<{ $selectedGroups?: number }>`
    display: ${(props) =>
        props.$selectedGroups && props.$selectedGroups > 0 ? 'block' : 'none'};
    padding-top: 8px;
    max-height: ${(props) =>
        props.$selectedGroups && props.$selectedGroups > 5
            ? 'calc(100vh - 316px)'
            : 'auto'};
    overflow-y: ${(props) =>
        props.$selectedGroups && props.$selectedGroups > 5 ? 'auto' : 'unset'};
    & > div {
        margin-right: 0;
        margin-left: 0;
        & > div {
            border: 0;
            & > div:first-child {
                display: none;
            }
        }
    }
`;

const StyledFooter = styled.div``;

const StyledButtons = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px;
`;

const StyledBtnRight = styled.div`
    display: flex;
    gap: 8px;
`;

const StyledBtnClearAll = styled(Button)`
    background-color: ${(props) => props.theme.colors.neutral['10']};
`;

const StyledBtnCancel = styled(Button)`
    width: 100px;
`;

const StyledBtnApply = styled(Button)`
    width: 100px;
`;

const StyledDivider = styled.div`
    height: 2px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    width: 100%;
`;
