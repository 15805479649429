import {
    Button,
    Icons,
    SideMenu,
    TabSelector,
    styled,
    useToast,
} from '@keypro/2nd-xp';
import { PrintMenuSettingPart } from './PrintMenuSettingPart';
import { PrintMenuLegendPart } from './PrintMenuLegendPart';
import { PrintMenuThemeLegendPart } from './PrintMenuThemeLegendPart';
import { t } from 'i18next';
import { useCenterMenu, useRightMenu } from '@stores';
import { PrintArgs } from '@generated';
import { quickPrint } from '@apis/keycore/quickPrint';

export const PrintMenuContainer = () => {
    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const { setMenuContent: setCenterMenuContent } = useCenterMenu();
    const toast = useToast();

    const closePrintMenus = () => {
        setRightMenuContent(t('print'), <></>);
        setCenterMenuContent(t('print'), <></>);
    };

    const print = async () => {
        try {
            const res = await quickPrint(data as PrintArgs);
            if (res.result?.data && res.result.data !== null) {
                const printUrl = '/proxy/' + res.result.data.toString();
                closePrintMenus();

                window.open(printUrl, '_blank')?.focus();
            }
        } catch {
            toast.show(t('printError'));
        }
    };

    return (
        <StyledPrintMenuContainer>
            <StyledPrintMenuContainerHeader>
                <StyledPrintMenuContainerTitle>
                    {t('print')}
                </StyledPrintMenuContainerTitle>
                <StyledPrintMenuContainerTitleBtn
                    kind="ghost"
                    onClick={closePrintMenus}
                >
                    <Icons.Cross2 />
                </StyledPrintMenuContainerTitleBtn>
            </StyledPrintMenuContainerHeader>
            <StyledPrintMenuContainerContent>
                <StyledPrintMenuContainerTabsPrintBtn>
                    <StyledPrintMenuContainerTabs
                        options={[
                            { id: '1', label: t('quick') },
                            { id: '2', label: t('series') },
                        ]}
                        selected="1"
                    />
                    <Button
                        data-testid="printMenu-printBtn"
                        label={t('print')}
                        kind="primary"
                        onClick={print}
                    />
                </StyledPrintMenuContainerTabsPrintBtn>
                <StyledPrintMenuContainerSettings>
                    <PrintMenuSettingPart />
                    <PrintMenuLegendPart />
                    <PrintMenuThemeLegendPart />
                </StyledPrintMenuContainerSettings>
            </StyledPrintMenuContainerContent>
        </StyledPrintMenuContainer>
    );
};

const StyledPrintMenuContainer = styled(SideMenu)`
    width: auto;
    & > div:first-child {
        padding: 12px;
    }
    position: absolute;
    right: 0;
`;

const StyledPrintMenuContainerHeader = styled.div`
    display: flex;
    align-items: center;
`;

const StyledPrintMenuContainerTitle = styled.div`
    margin-right: auto;
    padding-left: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    ${(props) => props.theme.fonts['18px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledPrintMenuContainerTitleBtn = styled(Button)`
    padding: 0;
    width: 32px;
    color: ${(props) => props.theme.colors.neutral['90']};
    & > svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledPrintMenuContainerContent = styled.div`
    overflow: scroll;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
`;

const StyledPrintMenuContainerTabsPrintBtn = styled.div`
    padding: 16px;
    display: flex;
    & > button {
        width: 108px;
    }
    gap: 12px;
`;

const StyledPrintMenuContainerTabs = styled(TabSelector)`
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    & > button {
        color: ${(props) => props.theme.colors.neutral['90']};
        font-weight: 700;
        line-height: 20px;
        width: 88px;
    }
`;

const StyledPrintMenuContainerSettings = styled.div`
    display: grid;
    gap: 8px;
`;

const data = {
    params: [
        {
            page_info: {
                is_index: false,
                papersize: 'A4',
                page_h: 595.275590551181,
                page_w: 841.8897637795276,
                geojson:
                    '{"type":"Feature","properties":{"_meta":{"active_z_lock":true,"active_z":"0"}},"geometry":{"type":"Polygon","coordinates":[[[383427.98170264775,6678576.228484178,0],[383569.42614709225,6678576.228484178,0],[383569.42614709225,6678674.172928622,0],[383427.98170264775,6678674.172928622,0],[383427.98170264775,6678576.228484178,0]]]}}',
                dpi: 150,
                angle: 0,
                orientation: '0',
                order_no: 1,
                mapx: 383498.70392487,
                mapy: 6678625.2007064,
                scale: 500,
                legend_position: null,
                theme_legend_position: null,
                created_by: 'huy.tran.xuan@keypro.fi',
                remarks: '',
            },
            legend_id: null,
            layers_config: [
                {
                    layer_name: 'mml1',
                    layer_type: 'WMS',
                    layer_order: -1000,
                    layers: 'mml_keypro_color_europe',
                    url: 'https://a.tms.keypro.fi/mapcache/wms?service=WMS&layers=mml_keypro_color_europe&maptoken=3L15GbmRX2IUYaDVe7D8&SERVICE=WMS&VERSION=1.1.1&REQUEST=GETMAP&SRS=EPSG:3067&LAYERS=mml_keypro_color_europe&_indx=1&',
                },
                {
                    layer_name: 'ref_layer_61705e7540d042558450438f6c5eeda2',
                    layer_type: 'MapServer',
                    layer_order: 100,
                    layers: 'ref_layer',
                    url: 'https://keycom-stage.tests.keyprocloud.io/refmaps/layers/61705e7540d042558450438f6c5eeda2?layers=ref_layer&transparent=true&map_imagetype=agg/png24&mode=map&cscale=50000&',
                },
                {
                    layer_name: 'ref_layer_472af089f9014a598ec2ed7be6088bf1',
                    layer_type: 'MapServer',
                    layer_order: 101,
                    layers: 'ref_layer',
                    url: 'https://keycom-stage.tests.keyprocloud.io/refmaps/layers/472af089f9014a598ec2ed7be6088bf1?layers=ref_layer&transparent=true&map_imagetype=agg/png24&mode=map&cscale=50000&',
                },
                {
                    layer_name: 'ref_layer_28634cb67cf4490cbc9af6fdfce4c77b',
                    layer_type: 'MapServer',
                    layer_order: 102,
                    layers: 'ref_layer',
                    url: 'https://keycom-stage.tests.keyprocloud.io/refmaps/layers/28634cb67cf4490cbc9af6fdfce4c77b?layers=ref_layer&transparent=true&map_imagetype=agg/png24&mode=map&cscale=50000&',
                },
                {
                    layer_name: 'ref_layer_e36bbe04cf1c4914bf97c27ca8b4e44a',
                    layer_type: 'MapServer',
                    layer_order: 103,
                    layers: 'ref_layer',
                    url: 'https://keycom-stage.tests.keyprocloud.io/refmaps/layers/e36bbe04cf1c4914bf97c27ca8b4e44a?layers=ref_layer&transparent=true&map_imagetype=agg/png24&mode=map&cscale=50000&',
                },
                {
                    layer_name: 'ref_layer_0f86de4c30374f8597f72729d8c8b767',
                    layer_type: 'MapServer',
                    layer_order: 110,
                    layers: 'ref_layer',
                    url: 'https://keycom-stage.tests.keyprocloud.io/refmaps/layers/0f86de4c30374f8597f72729d8c8b767?layers=ref_layer&transparent=true&map_imagetype=agg/png24&mode=map&cscale=50000&',
                },
                {
                    layer_name: 'ref_layer_5e13795e90014bfa91d8803771271c72',
                    layer_type: 'MapServer',
                    layer_order: 113,
                    layers: 'ref_layer',
                    url: 'https://keycom-stage.tests.keyprocloud.io/refmaps/layers/5e13795e90014bfa91d8803771271c72?layers=ref_layer&transparent=true&map_imagetype=agg/png24&mode=map&cscale=50000&',
                },
                {
                    layer_name: 'keycore_survey_korostus',
                    layer_type: 'MapServer',
                    layer_order: 129,
                    layers: 'keycore_survey_korostus',
                    url: 'https://keycom-stage.tests.keyprocloud.io/maps/layers?layers=keycore_survey_korostus&transparent=true&mode=map&map_imagetype=png&cscale=50000&',
                },
                {
                    layer_name: 'highlight',
                    layer_type: 'MapServer',
                    layer_order: 199,
                    layers: 'korostus',
                    url: 'https://keycom-stage.tests.keyprocloud.io/maps/layers?layers=korostus&transparent=true&mode=map&map_imagetype=png&cscale=50000&',
                },
                {
                    layer_name: 'combo_keyrns',
                    layer_type: 'ComboMS',
                    layer_order: 500,
                    layers: 'keycom_servicearea',
                    url: 'https://keycom-stage.tests.keyprocloud.io/maps/layers?transparent=true&mode=map&map_imagetype=png&_olSalt=0.0792848045032879&layers=keycom_servicearea&cscale=50000&',
                },
                {
                    layer_name: 'routehighlight_v2',
                    layer_type: 'WMS',
                    layer_order: 6004,
                    layers: 'empty_layer',
                    url: 'https://keycom-stage.tests.keyprocloud.io/highlight?LAYERS=empty_layer&TRANSPARENT=true&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&FORMAT=image/png&cscale=50000&SRS=EPSG:3067&',
                },
            ],
            theme_legend_config: [],
            legend_extra_data: {},
        },
    ],
    method: 'quick_print',
    id: 3,
};
