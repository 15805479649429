import {
    NavigationBar,
    NavigationButton,
    Icons,
    SideMenu,
    Button,
} from '@keypro/2nd-xp';
import { useLeftMenu } from '@stores';
import { LayersButton } from './Layers/LayersButton';
import { MapViewButton } from './MapViews/MapViewButton';
import { t } from 'i18next';

/**
 * Placeholder menu for buttons in the navigation bar.
 */
const DummyLeftMenu = (): JSX.Element => {
    const { toggleMenu, menuContentId } = useLeftMenu();
    return (
        <SideMenu>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {menuContentId} WIP
                <Button kind="ghost" onClick={() => toggleMenu()}>
                    <Icons.Cross />
                </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                To be implemented...
            </div>
        </SideMenu>
    );
};

const AppNavigationBar = (): JSX.Element => {
    const { setMenuContent, toggleMenu, menuContentId, isMenuOpen } =
        useLeftMenu();

    // just a temporary function to set the active class on the navigation buttons
    // until each button has actually been implemented
    const getClassName = (label: string): string => {
        return menuContentId === label && isMenuOpen ? 'active' : '';
    };

    return (
        <NavigationBar id="navigation-bar">
            <NavigationButton
                data-testid="navigationbar-network"
                label={t('network')}
                className={getClassName('Network')}
                onClick={() => setMenuContent('Network', <DummyLeftMenu />)}
            >
                <Icons.Net />
            </NavigationButton>
            <LayersButton />
            <NavigationButton
                data-testid="navigationbar-saved"
                label={t('saved')}
                className={getClassName('Saved')}
                onClick={() => setMenuContent('Saved', <DummyLeftMenu />)}
            >
                <Icons.BookmarkSave />
            </NavigationButton>
            <NavigationButton
                data-testid="navigationbar-create"
                label={t('create')}
                className={getClassName('Create')}
                onClick={() => setMenuContent('Create', <DummyLeftMenu />)}
            >
                <Icons.Create />
            </NavigationButton>
            <MapViewButton />
            <NavigationButton
                data-tooltip-right={t('moreTooltip')}
                onClick={() => toggleMenu()}
            >
                <Icons.MoreHollow />
            </NavigationButton>
        </NavigationBar>
    );
};

export default AppNavigationBar;
