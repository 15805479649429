import {
    SideMenu,
    styled,
    Button,
    Icons,
    MapContext,
    TabSelector,
} from '@keypro/2nd-xp';
import { useContext, useState, useEffect } from 'react';
import LayerTreeView from './LayerTreeView';
import { t } from 'i18next';

const LayersMenuHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    max-height: 32px;

    .close-search-icon {
        width: 16px;
        height: 16px;
        path {
            stroke: ${({ theme }) => theme.colors.neutral['90']};
        }
    }
    .input-search-icon {
        width: 16px;
        height: 16px;
        & path {
            fill: ${({ theme }) => theme.colors.neutral['80']};
            stroke: ${({ theme }) => theme.colors.neutral['80']};
        }
    }
`;

/**
 * Placeholder layers/favourites selector.
 * to be implemented as real component later
 */
const LayerTabSelector = ({
    onSelect,
    selected,
}: {
    onSelect: (buttonId: string) => void;
    selected: string;
}): JSX.Element => {
    const button1Id = 'layers';
    const button2Id = 'favourites';
    const onClick = (buttonId: string) => {
        onSelect(buttonId);
    };
    return (
        <TabSelector
            options={[
                { id: button1Id, label: t('Layers') },
                { id: button2Id, label: t('Favourites') },
            ]}
            selected={selected}
            onSelect={onClick}
        />
    );
};

const StyledInputField = styled.input`
    font: ${({ theme }) => theme.fonts['14px Regular']};
    line-height: 0;
    color: ${({ theme }) => theme.colors.neutral['100']};
    background-color: transparent;
    width: 100%;
    height: 18px;
    border: none;
    caret-color: ${({ theme }) => theme.colors.accents.blue['10']};
    &::placeholder {
        color: ${({ theme }) => theme.colors.neutral['80']};
    }
    &:focus {
        outline-color: transparent;
        outline-style: none;
    }
`;

const InputFieldIconContainer = styled.div`
    width: 16px;
    height: 16px;
`;

const SearchButton = styled(Button).attrs({ kind: 'secondary' })`
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.colors.neutral['30']};
    & svg {
        width: 16px;
        height: 16px;
        & path {
            fill: ${({ theme }) => theme.colors.neutral['90']};
            stroke: ${({ theme }) => theme.colors.neutral['90']};
        }
    }
`;

const LayerMenuHeaderContent = ({
    searchState,
    handleInputChange,
    toggleSearchState,
    toggleView,
}: {
    searchState: boolean;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    toggleSearchState: () => void;
    toggleView: (buttonId: string) => void;
}) => {
    const [selectedTab, setSelectedTab] = useState<string>('layers');
    const handleTabChange = (buttonId: string) => {
        toggleView(buttonId);
        setSelectedTab(buttonId);
    };
    if (searchState) {
        return (
            <>
                <InputFieldIconContainer>
                    <Icons.Search className="input-search-icon" />
                </InputFieldIconContainer>
                <StyledInputField
                    type="text"
                    placeholder={t('searchLayers')}
                    onChange={handleInputChange}
                    autoFocus
                />
                <Button
                    data-tooltip={t('closeSearch')}
                    kind="ghost"
                    onClick={toggleSearchState}
                    style={{ width: '24px', height: '24px' }}
                >
                    <Icons.Cross className="close-search-icon" />
                </Button>
            </>
        );
    } else {
        return (
            <>
                <LayerTabSelector
                    onSelect={handleTabChange}
                    selected={selectedTab}
                />
                <SearchButton
                    onClick={toggleSearchState}
                    data-tooltip={t('searchLayers')}
                >
                    <Icons.Search />
                </SearchButton>
            </>
        );
    }
};

export const LayersMenu = (): JSX.Element => {
    const controller = useContext(MapContext)!;
    const [isMapInitialized, setIsMapInitialized] = useState(
        controller.initialized,
    );
    const [searchState, setSearchState] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [visibleTree, setVisibleTree] = useState<'layers' | 'favourites'>(
        'layers',
    );

    useEffect(() => {
        const handleInitialize = () => {
            setIsMapInitialized(true);
        };

        controller.on('initialize', handleInitialize);

        return () => {
            controller.off('initialize', handleInitialize);
        };
    }, [controller]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const toggleSearchState = () => {
        setSearchState((prevState) => !prevState);
        setSearchValue('');
    };

    const toggleTreeView = (buttonId: string) => {
        if (buttonId === 'layers' || buttonId === 'favourites') {
            setVisibleTree(buttonId);
        }
    };

    return (
        <SideMenu data-testid="menu-layers">
            <LayersMenuHeader>
                <LayerMenuHeaderContent
                    toggleView={(buttonId) => toggleTreeView(buttonId)}
                    searchState={searchState}
                    handleInputChange={handleInputChange}
                    toggleSearchState={toggleSearchState}
                />
            </LayersMenuHeader>
            {isMapInitialized ? (
                <LayerTreeView
                    search={searchValue}
                    display={visibleTree}
                    key={visibleTree}
                />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Icons.Spinner />
                </div>
            )}
        </SideMenu>
    );
};
